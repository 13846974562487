import { Box, Button } from '@mui/material';
import React, { forwardRef, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';

const formateDate = (dateString) => {
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};
const formatDate = (date) => { 
  const d = new Date(date); 
  const monthNames = [ 'January', 'February','March', 'April','May', 'June','July','August','September','October','November','December'];
  const weekdayNames = [ 'Sunday', 'Monday','Tuesday', 'Wednesday','Thursday','Friday','Saturday'];

  const year = d.getFullYear(); 
  const month = monthNames[d.getMonth()]; 
  const day = d.getDate(); 
  const weekday = weekdayNames[d.getDay()]; 
 
  const getOrdinalSuffix = (n) => { 
    const s = ['th', 'st', 'nd', 'rd'];  
    const v = n % 100; 
    return s[(v - 20) % 10] || s[v] || s[0]; 
  }; 
  return `${weekday}, ${day}${getOrdinalSuffix(day)} ${month}, ${year}`; 
};

const QuotationPreviewTemplate = forwardRef(
  ({ children, previewData }, ref) => {
    console.log(previewData);
    const markup_amount = previewData?.markup_amount || 0;
    const is_confirmed = previewData.is_confirmed || false;
    const bookingID = is_confirmed
      ? `CNF/${previewData.bill_no}`
      : `${previewData.bill_no}`;
    const emailSubject = is_confirmed
      ? `Confirmed - ${bookingID}/${previewData.created_by}`
      : `Quotation - ${bookingID}/${previewData.created_by}`;
    const emailHeader = is_confirmed
      ? 'Congratulations! You have successfully booked the itinerary quote. We are thrilled to have the opportunity to serve you.'
      : 'Congratulations! You have successfully created the itinerary quote. We are thrilled to have the opportunity to serve you. We hope our rates are competitive and we can proceed with the booking.';
    const exclusion_list = [
      'Travel Insurance',
      'Any meal except for those mentioned in inclusions.',
      'Tickets to any attractions, museums, monuments except for those mentioned in inclusions.',
      'Local commute/travelling within the city except as mentioned in inclusions.',
      'Anything that is not mentioned clearly in the inclusions.',
    ];
    const totalPax =
      previewData.number_of_adults +
      previewData.number_of_children +
      previewData.number_of_infants;

    const perPersonMarkup = markup_amount / totalPax;
    const perAdultCost =
      previewData.number_of_adults > 0
        ? (
            previewData.total_adult_amount / previewData.number_of_adults +
            perPersonMarkup
          ).toFixed(2)
        : 0;
    const perChildCost =
      previewData.number_of_children > 0
        ? (
            previewData.total_child_amount / previewData.number_of_children +
            perPersonMarkup
          ).toFixed(2)
        : 0;
    const perInfantCost =
      previewData.number_of_infants > 0
        ? (
            previewData.total_infant_amount / previewData.number_of_infants +
            perPersonMarkup
          ).toFixed(2)
        : 0;
    const endDate = new Date(previewData.tour_start_date);
    endDate.setDate(endDate.getDate() + (previewData.duration - 1));
    return (
      <Box
        sx={{ overflowY: 'scroll', height: '90vh', width: '60vw', padding: 6 }}
      >
        <style>
          {`body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                margin: 0;
                padding: 0;
                background-color: #f9f9f9;
                color: #333;
            }
            .container {
                max-width: 800px;
                margin: 20px auto;
                padding: 20px;
                background-color: #fff;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            table, th, td {
                border: 1px solid #ddd;
            }
            th, td {
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: #f2f2f2;
            }
            h3, h4, h5 {
                margin-top: 0;
            }
            ul {
                list-style-type: none;
                padding: 0;
            }
            ul li {
                margin-bottom: 10px;
            }
            a {
                color: #007bff;
                text-decoration: none;
            }
            h4{
                font-size: 22px;
                font-weight: 600;
                margin: 5px;
            }
                ul{
                    margin-left: 15px;
                }
                    li{ 
                    margin-left: 15px;

                    }
            a:hover {
                text-decoration: underline;
            }`}
        </style>
        <div className="container" ref={ref}>
          {/* <h3>Hi {previewData.created_by},</h3> */}
          <p>{emailHeader}</p>
          <br />
          <h5 className="text-xl font-semibold">
            Here are a few important details about your booking:
          </h5>
          <br />
          <p>
            <strong>Itinerary:</strong>{' '}
            {previewData?.bookingName
              ? previewData?.bookingName
              : previewData?.itineraryName}
          </p>
          <p>
            <strong>
              Your trip to: {previewData.itineraryInfo.location || ''} (
              {previewData.itineraryInfo.days.length - 1 || 0} Nights /
              {previewData.itineraryInfo.days.length || 0} Days){' '}
            </strong>
            <br />
          </p>
          <p>
            <strong>Travel Date:</strong>
            {formatDate(previewData.tour_start_date)} to&nbsp;
            {formatDate(endDate)}
          </p>
          <p>
            <strong>Adults:</strong> {previewData.number_of_adults}
            <br />
            <strong>Children:</strong> {previewData.number_of_children}
            <br />
            <strong>Infants:</strong> {previewData.number_of_infants}
          </p>
          <p>
            <strong>Visa Included:</strong>
            {previewData.visaStatus ? 'Included' : 'Not Included'}
          </p>
          <table>
            <tr>
              <th>Reference Number</th>
              <th>Total Number of Pax</th>
              <th>Per Adult Cost {previewData.currency} </th>
              <th>Per Child Cost {previewData.currency} </th>
              <th>Per Infant Cost {previewData.currency} </th>
              <th>Total Cost {previewData.currency} </th>
            </tr>
            <tr>
              <td>{bookingID}</td>
              <td>{totalPax}</td>
              <td>{perAdultCost}</td>
              <td>{perChildCost}</td>
              <td>{perInfantCost}</td>
              <td>{previewData.total_amount}</td>
            </tr>
          </table>
          <h4>Inclusions:</h4>
          <ul> 
            {previewData.itineraryInfo.days.map((day, index) => ( 
              <li> 
                <strong> {index + 1}:</strong>{' '} 
                {day 
                  .map((d) => {
                    let transportLabel;
                    switch (d.transportType) {
                      case 'PT':
                        transportLabel = 'Private Transfer';
                        break;
                      case 'WOT':
                        transportLabel =
                          d.type === 'Meal'
                            ? 'Meal Transfer Prices'
                            : d.type === 'Entrance'
                            ? 'Tickets Only'
                            : 'Without Transfer';
                        break;
                      case 'ST':
                        transportLabel = 'Shared Transfer';
                        break;
                      default:
                        transportLabel = 'Transfer';
                    }
          
                    return `${d.activityName} (${transportLabel})`;
                  })
                  .join(', ')}
              </li>
            ))}
          </ul>
          <h4>Activity Details:</h4>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Day</th>
                <th>Activity Name</th>
              </tr>
            </thead>
            <tbody>
              {previewData.itineraryInfo.days.map((day, index) => {
                const activityDate = new Date(previewData.tour_start_date);
                activityDate.setDate(activityDate.getDate() + index);
                return day.map((d, activityIndex) =>
                  activityIndex === 0 ? (
                    <tr>
                      <td rowspan={`${day.length}`}>
                        {formatDate(activityDate)}
                      </td>
                      <td rowspan={`${day.length}`}>Day: {index + 1}</td>
                      <td>{d.activityName}</td>
                    </tr>
                  ) : (
                    <tr>
                      <td>{d.activityName}</td>
                    </tr>
                  ),
                );
              })}
            </tbody>
          </table>
          {previewData.exclusion.length > 0 ? (
            <>
              <h4>Exclusions:</h4>
              <ul className="list-disc">
                {previewData.exclusion.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <h4>Exclusions:</h4>
              <ul className="list-disc">
                {exclusion_list.map((exclusion) => (
                  <li>{exclusion}</li>
                ))}
              </ul>
            </>
          )}
          {previewData.visaStatus ? (
            <>
              <h4>Visa Checklist:</h4>
              <ul className="ml-10 list-disc">
                {previewData.visa.checklist.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <></>
          )}
          {previewData.visaStatus ? (
            <>
              <h4>Visa Notes:</h4>
              <ul className="ml-10 list-disc">
                {previewData.visa.note.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <></>
          )}
          {previewData.document_required.length > 0 ? (
            <>
              <h4>Document Required:</h4>
              <ul className="ml-10 list-disc">
                {previewData.document_required.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <h4>Document Required:</h4>
              <ul className="ml-10 list-disc">
                <li>
                  The following documents must be submitted upon booking
                  confirmation:
                </li>
                <li>A copy of PAN card for each passenger.</li>
                <li>A copy of Passport for each passenger.</li>
                <li> A copy of Visa/Ticket for each passenger.</li>
              </ul>
            </>
          )}
          {previewData.terms_n_conditions.length > 0 ? (
            <>
              <h4>Terms and Conditions:</h4>
              <ul className="ml-10 list-disc">
                {previewData.terms_n_conditions.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <h4>Terms and Conditions:</h4>
              <ul className="ml-10 list-disc">
                <li>
                  The above rates are valid for a minimum of 2 passengers
                  traveling together.
                </li>
                <li>
                  The above rates are not applicable during exhibitions or peak
                  dates. Eatables are strictly prohibited in vehicles.
                </li>
                <li>
                  Passengers are required to carry their own masks and hand
                  sanitizer for regular use.
                </li>
                <li>
                  Room occupancy and park capacity for attractions are subject
                  to availability.
                </li>
                <li>
                  Cancellation of attractions is not possible once issued, as
                  all are dated and must be pre-booked.
                </li>
                <li>
                  Please note that the above rates are net, non-commissionable,
                  and applicable only for our B2B travel partners.
                </li>
              </ul>
            </>
          )}
          {previewData.important_note.length > 0 ? (
            <>
              <h4>Important Note:</h4>
              <ul className="ml-10 list-disc">
                {previewData.important_note.map((list) => (
                  <li>{list}</li>
                ))}
              </ul>
            </>
          ) : (
            <>
              <h4>Important Note:</h4>
              <ul className="ml-10 list-disc">
                <li>
                  Any special requests such as adjacent rooms, interconnecting
                  rooms, rooms on the same floor, 1 king-size/1 double bed,
                  early check-in, late check-out, non-smoking rooms, etc., are
                  subject to availability upon guest check-in and at the hotel's
                  discretion.
                </li>
                <li>
                  We will prioritize these requests but cannot guarantee them at
                  any point.
                </li>
                <li>
                  Thank you for your attention to these details. Should you have
                  any questions or require further assistance, please do not
                  hesitate to contact us.
                </li>
              </ul>
            </>
          )}
          <br />
          <p>
            We are excited to see the value you will bring to our platform.
            Thank you for choosing to work with us!
          </p>
          <p>
            Best regards,
            <br />
            Team BookOnABudget
          </p>
          <p>
            Login with us -{' '}
            <a href="https://www.bookonabudget.com">bookonabudget.com</a>
          </p>
        </div>
      </Box>
    );
  },
);

const QuotationPreview = ({ data: previewData }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: previewData.itineraryName || 'Tour',
    removeAfterPrint: true,
  });

  return (
    <>
      <div className="flex justify-center w-full">
        <Button variant="contained" onClick={handlePrint} sx={{ marginTop: 4 }}>
          Download
        </Button>
      </div>
      <QuotationPreviewTemplate ref={componentRef} previewData={previewData} />
    </>
  );
};

export default QuotationPreview;

import { uploadImages } from '../../../../helpers/imageUploadHelper';
import countryData from '../../../data1.json';

export const TOAST_CONFIG = {
  position: 'top-right',
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const getActivityPrice = (activity, transport, noa, noc) => {
  // console.log('transport: ', transport)
  if (['WOT', 'PT', 'ST'].includes(transport)) {
    let cost;
    if (transport === 'WOT') {
      cost =
        activity.price[transport]['AdultCost'] * noa +
        activity.price[transport]['ChildCost'] * noc;
      return cost;
    } else if (transport === 'ST') {
      cost =
        (activity.price[transport]['AdultCost'] +
          activity.price['WOT']['AdultCost']) *
          noa +
        (activity.price[transport]['ChildCost'] +
          activity.price['WOT']['ChildCost']) *
          noc;
      return cost;
    } else {
      let totalPrice = 0;
      let zerosArray = Array.from({
        length: activity.price.PT.length,
      }).fill(0);
      let maxPassengersArray = activity.price.PT.map((obj) => [
        obj.maxPassengers,
        obj.price,
      ]);
      maxPassengersArray.sort((x, y) => x[0] - y[0]);
      // console.log('max pas arr: ', maxPassengersArray)
      let totalPassengers = noa + noc;
      while (totalPassengers > 0) {
        let i = 0,
          flag = 0;
        while (i < maxPassengersArray.length) {
          if (parseInt(maxPassengersArray[i][0]) >= totalPassengers) {
            zerosArray[i] = zerosArray[i] + 1;
            flag = 1;
            break;
          } else if (i == maxPassengersArray.length - 1) {
            zerosArray[i] = zerosArray[i] + 1;
            break;
          }
          i = i + 1;
        }
        if (flag) {
          totalPassengers = -1;
        } else {
          totalPassengers =
            totalPassengers -
            parseInt(maxPassengersArray[maxPassengersArray.length - 1][0]);
        }
      }
      for (let i = 0; i < maxPassengersArray.length; i++) {
        totalPrice =
          totalPrice + zerosArray[i] * parseInt(maxPassengersArray[i][1]);
      }
      totalPrice =
        totalPrice +
        activity.price['WOT']['AdultCost'] * noa +
        activity.price['WOT']['ChildCost'] * noc;
      // console.log('total price: ', totalPrice)
      return totalPrice;
    }
  }
  return 0;
};

export const addDaysToDate = (inputDate, numDays) => {
  // Splitting the input date string into year, month, and day components
  var parts = inputDate.split('-');
  var year = parseInt(parts[0]);
  var month = parseInt(parts[1]) - 1; // Adjusting month to zero-based index
  var day = parseInt(parts[2]);

  // Creating a Date object with the provided date
  var date = new Date(year, month, day);

  // Adding the specified number of days
  date.setDate(date.getDate() + numDays);

  // Formatting the resulting date as 'yyyy-MM-dd'
  var result =
    date.getFullYear() +
    '-' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + date.getDate()).slice(-2);
  return result;
};

export const replaceImgWithUrls = async (formObj) => {
  const activityImageUrlArr = await uploadImages(formObj.images, 'itineraries');
  formObj.images = activityImageUrlArr;
};

export const getStateAndCity = (country) => {
  const stateArr = countryData.find((c) => c.name === country)?.states || [];
  const cityArr = stateArr[0]?.cities || [];
  return { stateArr, cityArr };
};

/**
 * Checks if there is a Saturday or Sunday within the date range from startDate to endDate.
 *
 * @param {string | Date} startDate - The start date of the range.
 * @param {string | Date} endDate - The end date of the range.
 * @returns {boolean} - Returns true if a Saturday or Sunday is within the range, else false.
 */
export function isWeekendWithinRange(startDate, endDate) {
  // Convert input to Date objects if they aren't already
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Iterate from start to end
  let currentDate = new Date(start);

  while (currentDate <= end) {
    // getDay() returns 0 for Sunday, 6 for Saturday
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      // 6: Saturday, 0: Sunday
      return true;
    }
    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // No Saturday or Sunday found in the range
  return false;
}

/**
 * Checks if at least one of the startDate or endDate falls within any festive season range.
 *
 * @param {string | Date} startDate - The start date of the booking.
 * @param {string | Date} endDate - The end date of the booking.
 * @param {Array} festiveSeasons - Array of festive seasons with start and end dates.
 * @returns {boolean} - Returns true if either startDate or endDate is within any festive season, else false.
 */
export function isDateWithinFestiveSeasons(startDate, endDate, festiveSeasons) {
  // Convert input dates to Date objects if they aren't already
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Helper function to check if a single date is within any festive season
  function isWithinAnyFestiveSeason(date, seasons) {
    return seasons.some((season) => {
      const festStart = new Date(season.start);
      const festEnd = new Date(season.end);
      return date >= festStart && date <= festEnd;
    });
  }

  // Check if startDate is within any festive season
  const isStartFestive = isWithinAnyFestiveSeason(start, festiveSeasons);

  // Check if endDate is within any festive season
  const isEndFestive = isWithinAnyFestiveSeason(end, festiveSeasons);

  // Return true if either startDate or endDate is festive
  return isStartFestive || isEndFestive;
}

/**
 * Checks if a booking includes a weekend and if either startDate or endDate falls within any festive season.
 *
 * @param {string | Date} startDate - The start date of the booking.
 * @param {string | Date} endDate - The end date of the booking.
 * @param {Array} festiveSeasons - Array of festive seasons with start and end dates.
 * @returns {Object} - An object containing:
 *   - hasWeekend: {boolean} indicating presence of a weekend.
 *   - hasFestiveDate: {boolean} indicating if either startDate or endDate is within a festive season.
 */
export function checkBookingDatesComprehensive(
  startDate,
  endDate,
  festiveSeasons,
) {
  // Helper function to check for weekends
  function hasWeekend(start, end) {
    let currentDate = new Date(start);
    while (currentDate <= end) {
      const dayOfWeek = currentDate.getDay();
      if (dayOfWeek === 6 || dayOfWeek === 0) {
        // Saturday or Sunday
        return true;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return false;
  }

  // Helper function to check if either startDate or endDate is within any festive season
  function hasFestiveDate(start, end, seasons) {
    // Nested function to check a single date
    function isWithinAnyFestiveSeason(date) {
      return seasons.some((season) => {
        const festStart = new Date(season.start);
        const festEnd = new Date(season.end);
        return date >= festStart && date <= festEnd;
      });
    }

    const isStartFestive = isWithinAnyFestiveSeason(start);
    const isEndFestive = isWithinAnyFestiveSeason(end);

    return isStartFestive || isEndFestive;
  }

  // Convert inputs to Date objects
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Validate dates
  if (isNaN(start) || isNaN(end)) {
    throw new Error('Invalid startDate or endDate');
  }

  // Ensure start is before or equal to end
  if (start > end) {
    throw new Error('startDate must be before or equal to endDate');
  }

  // Check for weekends
  const weekend = hasWeekend(start, end);

  // Check for festive dates
  const festive = hasFestiveDate(start, end, festiveSeasons);

  return {
    hasWeekend: weekend,
    hasFestiveDate: festive,
  };
}

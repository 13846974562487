import React from 'react';
import { Field } from 'formik';
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
} from '@mui/material';
import CustomSelect from '../../../../components/customSelect/CustomSelect';

export const HotelItem = ({
  index,
  idx,
  values,
  hotelData,
  dispatch,
  state,
  currencies,
}) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '0.3rem' }}>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={1} md={3}>
          <Typography fontWeight={700}>Hotel: {idx + 1}</Typography>
          <Field
            className="custom-select"
            name={`hotel.${index}.${idx}.id`}
            options={
              hotelData?.data.filter(
                (x) =>
                  x.location.toLowerCase() === values.location.toLowerCase(),
              ) || []
            }
            fieldName={'hotelName'}
            fieldValue={'_id'}
            component={CustomSelect}
            placeholder="Select Hotel"
            isMulti={false}
            custom={true}
            cb={(e) => {
              dispatch({
                type: 'CHANGE_SINGLE_HOTEL',
                payload: { id: e, index, idx, hotelData },
              });
              // if (state.days[index][idx].transportType) {
              //   dispatch({
              //     type: 'CHANGE_TRANSFER',
              //     payload: {
              //       index,
              //       idx,
              //       transportType: state.days[index][idx].transportType,
              //     },
              //   });
              // }
            }}
          />
        </Grid>
        <Grid item sm={2} md={3}>
          <Typography fontWeight={700}>Room Option</Typography>
          <Field
            className="custom-select"
            name={`hotel.${index}.${idx}.room_id`}
            fieldName={'type'}
            fieldValue={'_id'}
            options={state.hotel[index][idx]?.hotel.rooms || []}
            component={CustomSelect}
            placeholder="Select Room"
            isMulti={false}
            custom={false}
            cb={(e) => {
              const selectedRoom = state.hotel[index][idx].hotel.rooms.find(
                (x) => x._id.toString() === e.toString(),
              );
              dispatch({
                type: 'CHANGE_SINGLE_ROOM',
                payload: {
                  index,
                  idx,
                  room: selectedRoom,
                },
              });
              dispatch({
                type: 'CHANGE_ROOM_QTY',
                payload: {
                  index,
                  idx,
                  qty: 1,
                  startDate: values.tour_start_date,
                  endDate: values.tour_end_date,
                },
              });
            }}
          />
        </Grid>
        <Grid item sm={12} md={1}>
          <Typography fontWeight={700}>Quantity</Typography>
          <FormControl>
            <Field
              as={TextField}
              className="custom-select"
              name={`hotel.${index}.${idx}.quantity`}
              id={`hotel.${index}.${idx}.quantity`}
              defaultValue={1}
              type="number"
              fullWidth
              select={true}
              required
              onChange={(e) => {
                dispatch({
                  type: 'CHANGE_ROOM_QTY',
                  payload: {
                    index,
                    idx,
                    qty: e.target.value,
                    startDate: values.tour_start_date,
                    endDate: values.tour_end_date,
                  },
                });
              }}
            >
              {Array.from({ length: 30 }, (_, index) => (
                <MenuItem key={index} value={index}>
                  {index}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography fontWeight={700}>Adults</Typography>
          <FormControl>
            <Field
              className="custom-select"
              as={TextField}
              placeholder="Adult"
              value={
                state.hotel[index][idx].hotel.hotelName
                  ? state.hotel[index][idx].room.capacityAdult
                  : 0
              }
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography fontWeight={700}>Childrens</Typography>
          <Field
            className="custom-select"
            as={TextField}
            placeholder="Child"
            disabled
            value={
              state.hotel[index][idx].hotel.hotelName
                ? state.hotel[index][idx].room.capacityChild
                : 0
            }
          />
        </Grid>
        <Grid item sm={2} md={1}>
          <Typography fontWeight={700}>Infants</Typography>
          <Field
            className="custom-select"
            as={TextField}
            placeholder="Infant"
            disabled
            value={
              state.hotel[index][idx].hotel.hotelName
                ? state.hotel[index][idx].room.capacityInfant
                : 0
            }
          />
        </Grid>
        <Grid item sm={2} md={2}>
          <Typography fontWeight={700}>Total Price</Typography>
          <Typography fontWeight={800} alignItems="center" display="flex">
            {currencies[values.country][0].code}{' '}
            {parseFloat(
              state.hotel?.[index]?.[idx]?.totalRoomPrice || 0.0,
            ).toFixed(2) || 0.0}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
